.sendbird-message-search-pannel {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 100%;
  box-sizing: border-box; }
  .sendbird-theme--light .sendbird-message-search-pannel {
    background-color: var(--sendbird-light-background-50); }
  .sendbird-theme--dark .sendbird-message-search-pannel {
    background-color: var(--sendbird-dark-background-600); }
  .sendbird-message-search-pannel .sendbird-message-search-pannel__header {
    position: relative;
    display: flex;
    width: 100%;
    height: 64px;
    min-height: 64px;
    box-sizing: border-box; }
    .sendbird-theme--light .sendbird-message-search-pannel .sendbird-message-search-pannel__header {
      border-bottom: 1px solid var(--sendbird-light-onlight-04); }
    .sendbird-theme--dark .sendbird-message-search-pannel .sendbird-message-search-pannel__header {
      border-bottom: 1px solid var(--sendbird-dark-ondark-04); }
    .sendbird-message-search-pannel .sendbird-message-search-pannel__header .sendbird-message-search-pannel__header__title {
      position: absolute;
      top: 20px;
      left: 24px;
      width: calc(100% - 74px);
      overflow-x: hidden;
      word-break: keep-all;
      text-overflow: ellipsis; }
    .sendbird-message-search-pannel .sendbird-message-search-pannel__header .sendbird-message-search-pannel__header__close-button {
      position: absolute;
      top: 16px;
      right: 16px; }
  .sendbird-message-search-pannel .sendbird-message-search-pannel__input {
    display: flex;
    position: relative;
    padding: 16px;
    box-sizing: border-box; }
    .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container {
      position: relative;
      border-radius: 4px;
      width: 100%;
      height: 44px; }
      .sendbird-theme--light .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container {
        border: 1px solid var(--sendbird-light-onlight-03); }
      .sendbird-theme--dark .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container {
        border: 1px solid var(--sendbird-dark-ondark-03); }
      .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__search-icon {
        position: absolute;
        top: 10px;
        left: 16px; }
      .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__input-area {
        position: absolute;
        top: 14px;
        left: 48px;
        margin: 0px;
        padding: 0px;
        border: 0px;
        outline: none;
        font-size: 14px;
        width: calc(100% - 90px); }
        .sendbird-theme--light .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__input-area {
          color: var(--sendbird-light-onlight-01);
          background-color: var(--sendbird-light-background-50); }
        .sendbird-theme--dark .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__input-area {
          color: var(--sendbird-dark-ondark-01);
          background-color: var(--sendbird-dark-background-600); }
      .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__spinner {
        position: absolute;
        top: 12px;
        right: 12px; }
      .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__reset-input-button {
        position: absolute;
        top: 12px;
        right: 12px; }
        .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__reset-input-button:hover {
          cursor: pointer; }
  .sendbird-message-search-pannel .sendbird-message-search-pannel__message-search {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-y: scroll; }
