.sendbird-add-channel__rectangle-wrap {
  margin-top: 26px; }

.sendbird-add-channel__rectangle-footer {
  text-align: right;
  margin-top: 32px; }

.sendbird-add-channel__rectangle {
  width: 432px;
  height: 72px;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 25px 72px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer; }
  .sendbird-theme--light .sendbird-add-channel__rectangle {
    border: 1px solid var(--sendbird-light-onlight-04); }
  .sendbird-theme--dark .sendbird-add-channel__rectangle {
    border: 1px solid var(--sendbird-dark-ondark-04); }
  .sendbird-add-channel__rectangle .sendbird-icon {
    position: absolute;
    left: 22px;
    top: 24px; }
