.sendbird-user-list-item--small {
  display: block;
  position: relative;
  box-sizing: border-box;
  height: 40px; }
  .sendbird-user-list-item--small__avatar {
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 12px; }
  .sendbird-user-list-item--small .sendbird-muted-avatar {
    position: absolute;
    top: 10px;
    left: 12px;
    z-index: 2;
    pointer-events: none; }
  .sendbird-user-list-item--small__title {
    position: absolute;
    top: 10px;
    left: 54px;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .sendbird-user-list-item--small__subtitle {
    position: absolute;
    top: 23px;
    left: 140px;
    max-width: 237px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .sendbird-user-list-item--small__checkbox {
    position: absolute;
    top: 16px;
    right: 16px; }
  .sendbird-user-list-item--small__action {
    display: none;
    position: absolute;
    right: 10px;
    top: 4px; }
  .sendbird-user-list-item--small__operator {
    position: absolute;
    right: 24px;
    top: 10px; }
  .sendbird-theme--light .sendbird-user-list-item--small:hover, .sendbird-theme--light .sendbird-user-list-item--small.sendbird-icon--pressed {
    background-color: var(--sendbird-light-background-100); }
  .sendbird-theme--dark .sendbird-user-list-item--small:hover, .sendbird-theme--dark .sendbird-user-list-item--small.sendbird-icon--pressed {
    background-color: var(--sendbird-dark-background-500); }
  .sendbird-user-list-item--small:hover .sendbird-user-list-item--small__action, .sendbird-user-list-item--small.sendbird-icon--pressed .sendbird-user-list-item--small__action {
    display: block; }
  .sendbird-user-list-item--small:hover .sendbird-user-list-item--small__operator, .sendbird-user-list-item--small.sendbird-icon--pressed .sendbird-user-list-item--small__operator {
    display: none; }
  .sendbird-theme--light .sendbird-user-list-item--small.sendbird-icon--pressed .sendbird-iconbutton {
    background-color: var(--sendbird-light-primary-100); }
  .sendbird-theme--dark .sendbird-user-list-item--small.sendbird-icon--pressed .sendbird-iconbutton {
    background-color: var(--sendbird-dark-background-700); }
