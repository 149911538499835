.sendbird-notification {
  margin-top: 8px;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 4px;
  padding: 0px 2px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .sendbird-theme--light .sendbird-notification {
    background-color: var(--sendbird-light-primary-300); }
  .sendbird-theme--dark .sendbird-notification {
    background-color: var(--sendbird-dark-primary-200); }
  .sendbird-notification:hover {
    cursor: pointer; }
    .sendbird-theme--light .sendbird-notification:hover {
      background-color: var(--sendbird-light-primary-400); }
    .sendbird-theme--dark .sendbird-notification:hover {
      background-color: var(--sendbird-dark-primary-300); }
  .sendbird-theme--light .sendbird-notification.sendbird-notification--frozen {
    background-color: var(--sendbird-light-information-100); }
  .sendbird-theme--dark .sendbird-notification.sendbird-notification--frozen {
    background-color: var(--sendbird-dark-information-100); }
  .sendbird-theme--light .sendbird-notification.sendbird-notification--frozen .sendbird-notification__text {
    color: var(--sendbird-light-onlight-01); }
  .sendbird-theme--dark .sendbird-notification.sendbird-notification--frozen .sendbird-notification__text {
    color: var(--sendbird-dark-onlight-01); }
  .sendbird-notification .sendbird-notification__text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px; }
