.sendbird-openchannel-profile {
  position: relative;
  text-align: center;
  padding: 32px 40px 27px 40px; }
  .sendbird-theme--light .sendbird-openchannel-profile {
    border-bottom: 1px solid var(--sendbird-light-onlight-04);
    background-color: var(--sendbird-light-background-50); }
  .sendbird-theme--dark .sendbird-openchannel-profile {
    border-bottom: 1px solid var(--sendbird-dark-ondark-04);
    background-color: var(--sendbird-dark-background-600); }
  .sendbird-openchannel-profile .sendbird-openchannel-profile--inner {
    height: 116px; }
  .sendbird-openchannel-profile .sendbird-openchannel-profile__avatar {
    margin-bottom: 16px;
    text-align: center; }
  .sendbird-openchannel-profile .sendbird-openchannel-profile__title {
    max-width: 240px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    height: 18px; }
  .sendbird-openchannel-profile .sendbird-openchannel-profile__edit {
    position: absolute;
    top: 10px;
    right: 16px; }
