.sendbird-openchannel-conversation-scroll {
  position: relative;
  width: 100%; }
  .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container {
    position: sticky;
    width: 100%;
    bottom: 0px;
    height: 100%; }
    .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__padding {
      height: 8px; }
    .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button {
      position: sticky;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 8px;
      left: calc(50% - 20px);
      width: 40px;
      height: 40px;
      border-radius: 24px;
      z-index: 1; }
      .sendbird-theme--light .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button {
        background-color: var(--sendbird-light-primary-300);
        box-shadow: var(--sendbird-light-shadow-05); }
      .sendbird-theme--dark .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button {
        background-color: var(--sendbird-dark-primary-200);
        box-shadow: var(--sendbird-dark-shadow-05); }
      .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button:hover {
        cursor: pointer; }
        .sendbird-theme--light .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button:hover {
          background-color: var(--sendbird-light-primary-400); }
        .sendbird-theme--dark .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button:hover {
          background-color: var(--sendbird-dark-primary-300); }
      .sendbird-theme--light .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button:active {
        background-color: var(--sendbird-light-primary-500); }
      .sendbird-theme--dark .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button:active {
        background-color: var(--sendbird-dark-primary-400); }
    .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__item-container--no-messages {
      width: 100%;
      height: calc(100% - 8px); }
      .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__item-container--no-messages .sendbird-openchannel-conversation-scroll__container__place-holder {
        width: 100%;
        height: 100%; }
